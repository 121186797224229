this["templates"] = this["templates"] || {};
this["templates"]["pipods"] = this["templates"]["pipods"] || {};

this["templates"] = this["templates"] || {};
this["templates"]["songs"] = this["templates"]["songs"] || {};

this["templates"]["pipods"]["pipod"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, functionType="function", escapeExpression=this.escapeExpression;


  buffer += "<h2 class=\"name\">\n  ";
  if (helper = helpers.name) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.name); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\n</h2>\n\n<p>\n  <a href=\"#/\" class=\"btn btn-default\">\n    Change Venue\n  </a>\n</p>\n\n<ul class=\"songs\">\n</ul>\n";
  return buffer;
  });

this["templates"]["pipods"]["pipodItem"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, functionType="function", escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing;


  buffer += "<div class=\"panel panel-default\">\n  <h2 class=\"name panel-heading\">\n    ";
  if (helper = helpers.name) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.name); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\n  </h2>\n\n  <div class=\"panel-body\">\n    <div data-current-song></div>\n  </div>\n\n  <div class=\"time panel-footer\">\n    Playing since "
    + escapeExpression((helper = helpers.formatTime || (depth0 && depth0.formatTime),options={hash:{
    'format': ("HH:mm DD/MM/YYYY")
  },data:data},helper ? helper.call(depth0, (depth0 && depth0.playing_since), options) : helperMissing.call(depth0, "formatTime", (depth0 && depth0.playing_since), options)))
    + "\n  </div>\n</div>\n";
  return buffer;
  });

this["templates"]["pipods"]["pipods"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div class=\"row\">\n  <div class=\"col-sm-12\">\n    <h1>Venues</h1>\n\n    <p>Select a venue for history</p>\n\n    <ul class=\"pipods\">\n    </ul>\n  </div>\n</div>\n";
  });

this["templates"]["songs"]["song"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, functionType="function", escapeExpression=this.escapeExpression, self=this;

function program1(depth0,data) {
  
  var buffer = "", stack1, helper;
  buffer += "\n    ";
  if (helper = helpers.song_artist) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.song_artist); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\n  ";
  return buffer;
  }

function program3(depth0,data) {
  
  var stack1;
  stack1 = helpers['if'].call(depth0, ((stack1 = ((stack1 = (depth0 && depth0.now_playing)),stack1 == null || stack1 === false ? stack1 : stack1.metadata)),stack1 == null || stack1 === false ? stack1 : stack1.artist), {hash:{},inverse:self.noop,fn:self.program(4, program4, data),data:data});
  if(stack1 || stack1 === 0) { return stack1; }
  else { return ''; }
  }
function program4(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n    "
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.metadata)),stack1 == null || stack1 === false ? stack1 : stack1.artist)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\n  ";
  return buffer;
  }

function program6(depth0,data) {
  
  var buffer = "", stack1, helper;
  buffer += "\n    ";
  if (helper = helpers.song_title) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.song_title); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\n  ";
  return buffer;
  }

function program8(depth0,data) {
  
  var stack1;
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.metadata)),stack1 == null || stack1 === false ? stack1 : stack1.title), {hash:{},inverse:self.program(11, program11, data),fn:self.program(9, program9, data),data:data});
  if(stack1 || stack1 === 0) { return stack1; }
  else { return ''; }
  }
function program9(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n    "
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.metadata)),stack1 == null || stack1 === false ? stack1 : stack1.title)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\n  ";
  return buffer;
  }

function program11(depth0,data) {
  
  
  return "\n    <span class=\"unknown\">\n      Unknown\n    </span>\n  ";
  }

function program13(depth0,data) {
  
  
  return "\n<div class=\"progress\">\n  <div class=\"progress-bar\" role=\"progressbar\">\n  </div>\n</div>\n";
  }

  buffer += "<div class=\"artist\">\n  ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.from_playlist), {hash:{},inverse:self.program(3, program3, data),fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n</div>\n\n<div class=\"title\">\n  ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.from_playlist), {hash:{},inverse:self.program(8, program8, data),fn:self.program(6, program6, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n</div>\n\n";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.current_song), {hash:{},inverse:self.noop,fn:self.program(13, program13, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n";
  return buffer;
  });

this["templates"]["songs"]["songItem"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, self=this, functionType="function";

function program1(depth0,data) {
  
  
  return "current";
  }

function program3(depth0,data) {
  
  var buffer = "", helper, options;
  buffer += "\n      Playing since "
    + escapeExpression((helper = helpers.formatTime || (depth0 && depth0.formatTime),options={hash:{
    'format': ("HH:mm")
  },data:data},helper ? helper.call(depth0, (depth0 && depth0.created_at), options) : helperMissing.call(depth0, "formatTime", (depth0 && depth0.created_at), options)))
    + "\n    ";
  return buffer;
  }

function program5(depth0,data) {
  
  var buffer = "", helper, options;
  buffer += "\n      Played at "
    + escapeExpression((helper = helpers.formatTime || (depth0 && depth0.formatTime),options={hash:{
    'format': ("HH:mm")
  },data:data},helper ? helper.call(depth0, (depth0 && depth0.created_at), options) : helperMissing.call(depth0, "formatTime", (depth0 && depth0.created_at), options)))
    + "\n    ";
  return buffer;
  }

  buffer += "<div class=\"panel panel-default ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.current_song), {hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\">\n  <div class=\"panel-body\">\n    <div data-song></div>\n  </div>\n\n  <div class=\"time panel-footer\">\n    ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.current_song), {hash:{},inverse:self.program(5, program5, data),fn:self.program(3, program3, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n\n    from the ";
  if (helper = helpers.source_name) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.source_name); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + " ";
  if (helper = helpers.source_type) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.source_type); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\n  </div>\n</div>\n";
  return buffer;
  });
define("templates", ["handlebars"], (function (global) {
    return function () {
        var ret, fn;
        return ret || global.templates;
    };
}(this)));

